import { Navbar } from "components/navigation/Navbar";
import { makeApiRequest, RequestProps } from "utils/api";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react"; // Add useEffect import
import { Container } from "components/layout";
import { Event } from "types/Event";
import { isEqual, sortBy } from "lodash";
import { formatCurrency } from "utils/currency";
import { Link } from "react-router-dom";
import { SearchBar, SearchForm } from "./SearchBar";
import Footer from "components/navigation/Footer";
import { Spinner } from "components/loaders/Spinner";
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import CategoryTabs from "./CategoryTabs";
export type SearchFormProps = {
  featured: boolean;
  search: string;
  location: string;
  date: Date | null;
  [key: string]: string | Date | null | boolean;
};

export const EventSearch = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<SearchFormProps>({
    location: "",
    search: "",
    date: null,
    featured: true,
  });

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["events", searchQuery],
    queryFn: () => {
      const eventRequestParams: RequestProps = {
        path: "/events",
        params: searchQuery,
      };
      return makeApiRequest(eventRequestParams);
    },
    enabled: true,
  });

  const handleSearch = (formValues: SearchFormProps) => {
    if (isEqual(formValues, searchQuery)) {
      refetch();
    }
    setSearchQuery({ ...formValues, featured: false });
  };

  const events = data?.data;
  const featuredEvents: Event[] = events?.filter((e: Event) => e.featured);
  const nonFeaturedEvents: Event[] = events?.filter((e: Event) => !e.featured);
  useEffect(() => {
    if (searchQuery.location.length > 0 || searchQuery.search.length > 0) {
      refetch();
    } else {
      // show an error asking for a location or a search.
    }
  }, [searchQuery]); // This useEffect runs whenever searchQuery changes

  return (
    <div className="min-h-screen w-screen overflow-visible bg-gray-50">
      {/* <div className="absolute min-h-screen w-screen overflow-visible -z-10 m-auto" style={{backgroundImage: 'url(//lvgweb.s3.us-east-2.amazonaws.com/happy/events-bg.jpg)'}} /> */}
      <Navbar />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Container>
          <div className="flex w-full flex-col items-center mt-5 min-h-screen">
            <section className="space-y-4 w-full">
              {/* <CategoryTabs /> */}
              <SearchForm onSubmit={handleSearch} initialValues={searchQuery} />
            </section>
            <section className="w-full mt-5">
              {featuredEvents?.length ? (
                <div>
                  <h3 className="font-bold text-xl">Featured Near You</h3>
                  <div className="flex no-scrollbar overflow-y-visible overflow-x-auto gap-3 py-5 mt-2">
                    {featuredEvents.map((event: Event) => {
                      return (
                        <div
                          data-testid="featured-event"
                          role="button"
                          key={event.id}
                          onClick={() => navigate(`/events/${event.slug}`)}
                          className="sm:w-80 flex-shrink-0 mb-6 md:mb-0 h-[310px] w-5/6 relative shadow-lg hover:-translate-y-0.5 transition-all rounded-xl"
                        >
                          <div className="bg-white h-1/2 w-full rounded-tr-xl rounded-tl-xl overflow-hidden border-b">
                            <img
                              src={event.image_url}
                              alt=""
                              className="rounded-tr-xl rounded-tl-xl"
                            />
                          </div>

                          <div
                            style={{ background: "rgba(255,255,255,0.6)" }}
                            className="h-1/2 rounded-bl-xl rounded-br-xl p-3 flex flex-col justify-between"
                          >
                            <div>
                              <span className="font-bold text-gray-800 text-lg">
                                {event.name} @ {event.primary_venue?.name}
                              </span>
                              <br />
                              <span className="text-gray-500">
                                {format(
                                  new Date(event.start_date),
                                  "E, MMM do @ h:mm a"
                                )}
                              </span>
                            </div>

                            <div className="flex justify-between items-end text-gray-500">
                              <span>
                                From:{" "}
                                <span className="text-xl pl-2 text-pink">
                                  {formatCurrency(event.lowest_price)}
                                </span>
                              </span>

                              <span>
                                {event.city}, {event.state}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </section>

            {nonFeaturedEvents?.length ? (
              <>
                <div
                  className="h-5 w-full my-5"
                  style={{
                    backgroundImage:
                      "linear-gradient(269deg, #4CC9F0 0%, #4361EE 16%, #3A0CA3 38%, #7209B7 67%, #F72585 91%)",
                  }}
                />
                <div className="flex justify-between w-full flex-wrap">
                  <h3 className="font-bold text-xl">
                    All Events Near {searchQuery.location}:
                  </h3>
                  <div className="hidden mt-5 md:mt-0">
                    <div>
                      <span className="font-bold text-gray-600">
                        Art & Theater{" "}
                      </span>
                      <input
                        type="checkbox"
                        className="bg-gray-200 border-gray-200"
                      />
                    </div>

                    <div className="ml-5">
                      <span className="font-bold text-gray-600">Concerts </span>
                      <input
                        type="checkbox"
                        className="bg-gray-200 border-gray-200"
                      />
                    </div>

                    <div className="ml-5">
                      <span className="font-bold text-gray-600">Sports </span>
                      <input
                        className="bg-gray-200 border-gray-200"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              (searchQuery.location.length > 0 || searchQuery.search.length > 0) ? (
                <div className="w-full text-center bg-white p-5 border border-gray-100 rounded-md">
                  <h3 className="text-xl">No Events Found</h3>
                </div>
              ) : null
          
            )}
            <div className="w-full mt-6">
              {isFetching && (
                <div className="py-5">
                  <Spinner className="w-6 h-6" />
                </div>
              )}
              {sortBy(nonFeaturedEvents, "start_date").map((event: Event) => {
                const primaryVenue = event.primary_venue;
                return (
                  <div
                    key={event.id}
                    className="flex justify-between items-center w-full max-h-32 overflow-hidden border-b border-gray-400 py-3"
                  >
                    <div className="md:text-xl w-1/3 md:flex items-center">
                      <img
                        src={event.image_url}
                        className="hidden md:block md:w-[135px] md:h-auto"
                      />
                      <div className="md:pl-4 text-center">
                        <span className="font-bold text-pink">
                          {format(new Date(event.start_date), "MMM do")}
                        </span>
                        <br />
                        <span className="text-gray-700 text-sm">
                          {format(new Date(event.start_date), "h:mm a")}
                        </span>
                      </div>
                    </div>

                    <div className="w-full md:w-2/3 flex justify-between items-center">
                      <div>
                        <span className="font-bold md:text-xl">
                          {event.name}
                        </span>
                        <br />
                        <Link to={`/venues/${primaryVenue?.slug}`}>
                          <span className="text-gray-500">
                            {primaryVenue?.name} - {event.city}, {event.state}
                          </span>
                        </Link>
                      </div>

                      <Link
                        to={`/events/${event.slug}`}
                        className="text-center bg-transparent px-5 py-2 text-purple border border-purple rounded-full font-bold hover:brightness-110"
                      >
                        View Tickets
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </motion.div>
      <Footer />
    </div>
  );
};
