import {
  GoogleOAuthProvider,
  useGoogleLogin,
  CodeResponse,
} from "@react-oauth/google";
import { useAuth } from "providers/AuthContext";
export const GLogin = ({tos}: {tos: boolean}) => {
  const { loginOAuth } = useAuth();

  return (
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
      <GoogleLoginButton tos={tos} loginUser={loginOAuth} />
    </GoogleOAuthProvider>
  );
};

export const GoogleLoginButton = ({
  loginUser,
  tos,
}: {
  loginUser: (tokenResponse: CodeResponse) => void;
  tos: boolean;
}) => {
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => loginUser(tokenResponse),
    flow: "auth-code",
  });
  return (
    <button
      onClick={googleLogin}
      className="bg-blue hover:brightness-110 text-white font-bold py-2 px-4 rounded flex justify-center h-12 items-center gap-3 w-full"
    >
      {googleIcon}
      <span>Log in with Google</span>
    </button>
  );
};

const googleIcon = (
  <img src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg" />
);
