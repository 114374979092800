import { RegisterForm } from "components/forms/Auth";
import { Navbar } from "components/navigation/Navbar";
import { useAuth } from "providers/AuthContext";
import { useNotification } from "providers/NotificationContext";
import { useState } from "react";

export const Registration = () => {
  const { register } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const { showError } = useNotification();
  const handleSubmit = (values: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  }) => {
    setLoading(true);
    register(values)
      .then((res) => {
        if (res.status === 406) {
          showError(res.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Navbar />
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="fixed top-0 w-full">{/* <AlertBanner /> */}</div>
        <div className="max-w-md w-full space-y-2">
          <div className="flex justify-center items-center">
            <img
              className="w-12 py-4"
              src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
              alt="Happy Ticketing Company"
            />
            <div className="ml-4">
              <h2 className="text-3xl font-milkshake text-purple">
                Happy Ticketing
              </h2>
            </div>
          </div>
          <h3 className="text-center text-lg sm:text-xl font-extrabold">
            Create Account
          </h3>
          <RegisterForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </>
  );
};