import { useState, useEffect } from "react";

export const FadeText = () => {
  const texts = ["Concert", "Art Show", 'Parking Lot', "Festival", "Sporting Event", 'Convention', "Class", "Workshop", "Expo"];
  const [currentText, setCurrentText] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false); // Trigger fade-out
      setTimeout(() => {
        setCurrentText((prev) => (prev + 1) % texts.length); // Cycle through texts
        setFadeIn(true); // Trigger fade-in
      }, 200); // Duration of the fade-out effect
    }, 2000); // Change text every 3 seconds

    return () => clearInterval(interval); // Clean up the interval
  }, [texts.length]);

  return (
    <span className={`fade-text ${fadeIn ? "fade-in" : "fade-out"} text-4xl sm:text-5xl border-b-4  border-purple`}>
      {texts[currentText]}
    </span>
  );
};
