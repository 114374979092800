import { Container } from "components/layout";

export const TermsOfService = () => {
  return (
    <Container>
      <div className="mx-auto p-6">
        <h1 className="text-2xl font-bold text-center mb-6">
          Happy Ticketing Terms of Service
        </h1>
        <p className="text-sm text-gray-500 mb-6 text-center">
          Effective Date: May 30, 2024
        </p>

        <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By accessing or using Happy Ticketing's website, mobile applications,
          or other products and services (collectively, the "Services"), you
          agree to be bound by these Terms of Service ("Terms") and our Privacy
          Policy. If you do not agree to these Terms, please do not use the
          Services.
        </p>

        <h2 className="text-xl font-semibold mb-2">2. Our Services</h2>
        <p className="mb-4">
          Happy Ticketing provides an online platform that enables event
          organizers ("Organizers") to create, promote, and manage events, and
          allows consumers ("Users") to discover and purchase tickets for these
          events. Happy Ticketing is not the creator, organizer, or owner of the
          events listed on the Services; Organizers are solely responsible for
          their events.
        </p>

        <h2 className="text-xl font-semibold mb-2">3. Eligibility</h2>
        <p className="mb-4">
          You must be at least 18 years old and capable of entering into legally
          binding agreements to use the Services.
        </p>

        <h2 className="text-xl font-semibold mb-2">4. User Accounts</h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Account Creation:</strong> You may need to create an account
            to access certain features of the Services. You agree to provide
            accurate and complete information and to keep it updated.
          </li>
          <li>
            <strong>Account Security:</strong> You are responsible for
            safeguarding your account credentials. You must notify us
            immediately of any unauthorized use of your account.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">
          5. Event Listings and Ticket Purchases
        </h2>

        <h3 className="text-lg font-medium mb-2">5.1 Organizers</h3>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Responsibility:</strong> Organizers are solely responsible
            for their events, including event content, pricing, availability,
            and fulfillment.
          </li>
          <li>
            <strong>Compliance:</strong> Organizers must comply with all
            applicable laws, regulations, and industry standards.
          </li>
          <li>
            <strong>Licenses and Permits:</strong> Organizers must obtain all
            necessary licenses, permits, and authorizations for their events and
            provide evidence upon request.
          </li>
        </ul>

        <h3 className="text-lg font-medium mb-2">5.2 Users</h3>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Purchases:</strong> When you purchase a ticket, you agree to
            pay all charges, including applicable taxes and fees.
          </li>
          <li>
            <strong>Event Attendance:</strong> Your attendance at events is at
            your own risk. Any issues related to the event should be directed to
            the Organizer and Happy will not be held liable or responsible for
            any issues arising from attendance to the event.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">6. Payments and Fees</h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Service Fees:</strong> Happy Ticketing may charge fees to
            Organizers and Users. These fees will be disclosed prior to purchase
            or event listing.
          </li>
          <li>
            <strong>Payment Processing:</strong> We may use third-party payment
            processors. By making a purchase, you agree to the terms of the
            payment processor.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">
          7. Refunds and Cancellations
        </h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Organizer Policies:</strong> Refund and cancellation
            policies are determined by the Organizer and will be communicated at
            the time of purchase. Happy Ticketing Fees and Processing Fees are non-refundable.
          </li>
          <li>
            <strong>Requests:</strong> Users seeking refunds must contact the
            Organizer directly. Happy Ticketing may assist at its discretion but
            is not obligated to issue refunds.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">
          8. User Conduct and Content
        </h2>

        <h3 className="text-lg font-medium mb-2">8.1 Acceptable Use</h3>
        <ul className="list-disc list-inside mb-4">
          <li>Violate any laws or regulations.</li>
          <li>Post harmful, infringing, or illegal content.</li>
          <li>Engage in fraudulent or deceptive practices.</li>
          <li>Interfere with the operation of the Services.</li>
        </ul>

        <h3 className="text-lg font-medium mb-2">8.2 User Content</h3>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>License:</strong> By submitting content, you grant Happy
            Ticketing a worldwide, non-exclusive, royalty-free, transferable
            license to use, reproduce, modify, display, and distribute your
            content in connection with the Services.
          </li>
          <li>
            <strong>Responsibility:</strong> You are solely responsible for the
            content you submit and must have all necessary rights to grant the
            above license.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">
          9. Intellectual Property Rights
        </h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Ownership:</strong> All content and materials provided
            through the Services are owned by Happy Ticketing or its licensors.
          </li>
          <li>
            <strong>Restrictions:</strong> You may not copy, distribute, modify,
            or create derivative works from any part of the Services without our
            prior written consent.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">10. Privacy</h2>
        <p className="mb-4">
          Your use of the Services is subject to our Privacy Policy, which
          explains how we collect, use, and protect your personal information.
        </p>

        <h2 className="text-xl font-semibold mb-2">
          11. Disclaimer of Warranties
        </h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>"As Is" Basis:</strong> The Services are provided "as is"
            and "as available," without warranties of any kind, either express
            or implied.
          </li>
          <li>
            <strong>No Guarantee:</strong> We do not guarantee the accuracy,
            completeness, or reliability of any content or information provided
            through the Services.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">
          12. Limitation of Liability
        </h2>
        <p className="mb-4">
          In order to provide our Services on a large scale, we have to limit
          our liability to you. To the fullest extent permitted by applicable
          laws, Happy Ticketing and its affiliates, officers, directors,
          employees, agents, partners, and licensors (collectively, the "Happy
          Ticketing Released Parties") will not be liable to you or any third
          party for:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            Any indirect, incidental, special, consequential, punitive, or
            exemplary damages, including loss of profits, goodwill, data, etc.
          </li>
          <li>
            Any feedback, comments, or ratings that you give or receive in
            connection with the Services.
          </li>
          <li>Your content or trademarks.</li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">13. Indemnification</h2>
        <p className="mb-4">
          You agree to defend, indemnify, and hold harmless the Happy Ticketing
          Released Parties from any claims, liabilities, damages, losses, and
          expenses, including reasonable attorney's fees, arising out of or in
          any way connected with:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Your access to or use of the Services.</li>
          <li>Your violation of these Terms.</li>
          <li>
            Your violation of any rights of another party, including Organizers
            or other Users.
          </li>
          <li>
            Your content, events, or any activities conducted through your
            account.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">14. Dispute Resolution</h2>

        <h3 className="text-lg font-medium mb-2">14.1 Arbitration Agreement</h3>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Agreement to Arbitrate:</strong> You and Happy Ticketing
            agree to resolve any disputes or claims arising out of or relating
            to these Terms or the Services through binding arbitration, rather
            than in court.
          </li>
          <li>
            <strong>Class Action Waiver:</strong> All claims must be brought on
            an individual basis, and you waive any right to participate in a
            class, collective, or representative action.
          </li>
        </ul>

        <h3 className="text-lg font-medium mb-2">14.2 Arbitration Procedure</h3>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Rules:</strong> Arbitration will be administered by the
            American Arbitration Association ("AAA") under its Consumer
            Arbitration Rules.
          </li>
          <li>
            <strong>Location:</strong> The arbitration will take place in St.
            Petersburg, FL or at another mutually agreed location, or via
            telephone or video conference if in-person arbitration is not
            feasible.
          </li>
          <li>
            <strong>Fees:</strong> Each party is responsible for their own costs
            and attorney's fees, unless the arbitrator determines that a claim
            was frivolous.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">15. Governing Law</h2>
        <p className="mb-4">
          These Terms and any disputes arising out of or relating to them or the
          Services will be governed by the laws of the State of Florida,
          without regard to its conflict of law principles.
        </p>

        <h2 className="text-xl font-semibold mb-2">16. Changes to the Terms</h2>
        <p className="mb-4">
          We may update these Terms from time to time. If we make material
          changes, we will notify you by updating the "Effective Date" at the
          top of these Terms and, if appropriate, through additional
          communication. Your continued use of the Services after such changes
          constitutes acceptance of the new Terms.
        </p>

        <h2 className="text-xl font-semibold mb-2">17. Termination</h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>By You:</strong> You may terminate your account at any time.
          </li>
          <li>
            <strong>By Us:</strong> We may suspend or terminate your access to
            the Services at any time, with or without cause, and with or without
            notice.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">18. Miscellaneous</h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Entire Agreement:</strong> These Terms constitute the entire
            agreement between you and Happy Ticketing regarding the Services and
            supersede any prior agreements.
          </li>
          <li>
            <strong>Severability:</strong> If any provision of these Terms is
            found to be invalid or unenforceable, the remaining provisions will
            remain in full force and effect.
          </li>
          <li>
            <strong>Waiver:</strong> Our failure to enforce any right or
            provision of these Terms will not be deemed a waiver of such right
            or provision.
          </li>
          <li>
            <strong>Assignment:</strong> You may not assign or transfer these
            Terms without our prior written consent. We may assign these Terms
            without restriction.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">19. Contact Information</h2>
        <p className="mb-4">
          If you have any questions or concerns about these Terms, please
          contact us at:
        </p>
        <p className="mb-4">
          Happy Ticketing, LLC
          <br />
          Email:{" "}
          <a
            href="mailto:support@gethappyticketing.com"
            className="text-blue-500"
          >
            support@gethappyticketing.com
          </a>
        </p>

        <p className="text-sm mt-6">
          By using the Services, you acknowledge that you have read, understood,
          and agree to be bound by these Terms.
        </p>
      </div>
    </Container>
  );
};
