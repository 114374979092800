import { Container } from "components/layout";
import Footer from "components/navigation/Footer";
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { groupBy, keys, set, sortBy } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { Organization } from "types/Organization";
import { makeApiRequest } from "utils/api";
import { useState } from "react";
import classNames from "classnames";
import {
  BuildingStorefrontIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ListBulletIcon,
} from "@heroicons/react/24/solid";
import { Event } from "types/Event";
import { Navbar } from "components/navigation/Navbar";
import { Button } from "components/buttons";
import { formatCurrency } from "utils/currency";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const ViewOrganization = ({}: {}) => {
  const [selectedView, setSelectedView] = useState<"calendar" | "list">("list");
  const params = useParams();
  const { data } = useQuery({
    queryKey: ["organization"],
    queryFn: () =>
      makeApiRequest({
        path: `/organizations/${params.id}`,
      }),
  });

  const organization: Organization = data?.data;
  if (organization) {
    return (
      <>
        <Navbar />
        <Container className="mt-6">
          <div className="flex gap-3 items-center">
            {organization.image_url ? (
              <img src={organization.image_url} className="w-10 h-10" />
            ) : (
              <BuildingStorefrontIcon className="w-10 h-10 text-purple" />
            )}
            <div>
              <h1 className="text-3xl font-bold text-purple">
                {organization.name}
              </h1>
            </div>
          </div>
        </Container>
        <Container className="mb-40">
          <h2 className="text-xl font-bold mb-5 py-5">Upcoming Events</h2>

          <div className="mb-10">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                onChange={(e) => setSelectedView(e.target.value as any)}
                value={selectedView}
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-purple-500 focus:ring-purple-500"
                defaultValue={selectedView}
              >
                <option value="list">List</option>
                <option value="calendar">Calendar</option>
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="flex space-x-4" aria-label="Tabs">
                <span
                  onClick={() => setSelectedView("list")}
                  role="button"
                  aria-current={selectedView === "list" ? "page" : undefined}
                  className={classNames(
                    selectedView === "list"
                      ? "bg-purple/30 text-purple"
                      : "text-gray-500 hover:text-gray-700",
                    "rounded-md px-3 py-2 text-sm font-medium flex gap-1"
                  )}
                >
                  <ListBulletIcon className="w-5" /> List
                </span>

                <span
                  onClick={() => setSelectedView("calendar")}
                  role="button"
                  aria-current={
                    selectedView === "calendar" ? "page" : undefined
                  }
                  className={classNames(
                    selectedView === "calendar"
                      ? "bg-purple/30 text-purple"
                      : "text-gray-500 hover:text-gray-700",
                    "rounded-md px-3 py-2 text-sm font-medium flex gap-1"
                  )}
                >
                  <CalendarIcon className="w-5" /> Calendar
                </span>
              </nav>
            </div>
          </div>
          {selectedView === "list" && <ListView events={organization.events} />}
          {selectedView === "calendar" && <CalendarView />}
        </Container>
        <Footer />
      </>
    );
  }
  return null;
};

export const ListView = ({ events }: { events: Event[] }) => {
  const sorted = sortBy(events, "start_date");
  if (events) {
    return (
      <div>
        {sorted.map((event) => {
          return (
            <div className="flex justify-between items-center w-full border-b border-gray-400 py-3">
              <img
                src={event.image_url ||
                  "https://lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-black.svg"}
                className="md:block w-[90px] h-auto md:w-[90px] md:h-auto object-cover flex-shrink-0"
                alt={event.name}
              />

              <div className="flex-grow flex justify-between items-center ml-3"> 
                <div className="flex-grow">
                  <span className="font-bold md:text-xl">{event.name}</span>
                  <br />
                  <span className="font-bold text-pink text-sm">
                    {format(new Date(event.start_date), "E, MMM do ")}
                  </span>
                  <span className="text-gray-700 text-sm">
                    @ {format(new Date(event.start_date), "h:mm a")}
                  </span>
                  &nbsp;
                </div>

                <Link
                  to={`/events/${event.slug}`}
                  className="text-center bg-transparent px-5 py-2 text-purple border border-purple rounded-full font-bold hover:brightness-110 hover:bg-purple hover:text-white"
                >
                  View <span className="hidden md:inline">Tickets</span>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export const CalendarView = () => {
  const [showEventDetails, setShowEventDetails] = useState<Event | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const params = useParams();
  const { data } = useQuery({
    queryKey: ["event_calendar", selectedMonth, params.id],
    queryFn: () =>
      makeApiRequest({
        path: "/event_calendar",
        params: {
          organization_id: params.id,
          month: selectedMonth,
        },
      }),
    enabled: !!selectedMonth && !!params.id,
  });

  const events: Event[] = data?.data;

  const monthStart = startOfMonth(selectedMonth);
  const monthEnd = endOfMonth(selectedMonth);
  const days = eachDayOfInterval({
    start: startOfWeek(monthStart),
    end: endOfWeek(monthEnd),
  });

  const nextMonth = () => {
    setSelectedMonth((prev) => {
      return new Date(prev.getFullYear(), prev.getMonth() + 1);
    });
  };

  const prevMonth = () => {
    setSelectedMonth((prev) => {
      return new Date(prev.getFullYear(), prev.getMonth() - 1);
    });
  };

  const groupedEvents = groupBy(events, (e) =>
    format(new Date(e.start_date), "MMMM d, yyyy")
  );

  return (
    <>
      <div className="flex gap-3 w-full justify-center items-center my-4">
        <button onClick={prevMonth}>
          <ChevronDoubleLeftIcon className="w-4" />
        </button>
        <span className="font-bold">{format(selectedMonth, "MMMM yyyy")}</span>
        <button onClick={nextMonth}>
          <ChevronDoubleRightIcon className="w-4" />
        </button>
      </div>
      <div className="sm:hidden">
        {keys(groupedEvents).map((date) => {
          const dateEvents = groupedEvents[date];
          return (
            <div
              key={date}
              className="mt-4 w-full border-b border-gray-300 py-6 px-3"
            >
              <h2 className="text-xl font-bold flex gap-2">
                <CalendarDaysIcon className="w-6" />
                <span>{format(new Date(date), "EEEE, MMMM d, yyyy")}</span>
              </h2>
              {dateEvents?.map((event) => (
                <div
                  key={event.id}
                  className="text-lightPurple flex flex-col gap-2 font-bold mt-3"
                >
                  <div className="relative">
                    <img
                      src={event.image_url}
                      className="w-full h-auto rounded-lg"
                    />
                    <div className="text-lg w-full bg-black/80 absolute bottom-0 px-2 py-4 block leading-6 rounded-b-lg">
                      <span>
                        {event.name}
                        <br />
                        {event.primary_venue?.name} @{" "}
                        {format(new Date(event.start_date), "h:mm a")}
                      </span>
                      <br />
                      <Link to={`/events/${event.slug}`}>
                        <Button className="w-full mt-4 rounded-lg">
                          Get Tickets
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <div className="hidden sm:block">
        <div className="flex gap-0 mt-6">
          <span className="w-[14%] h-10 items-center text-center text-sm font-bold border-l border-t flex justify-center bg-blue text-white">
            Sun
          </span>
          <span className="w-[14%] h-10 items-center text-center text-sm font-bold border-t flex justify-center bg-blue text-white">
            Mon
          </span>
          <span className="w-[14%] h-10 items-center text-center text-sm font-bold border-t flex justify-center bg-blue text-white">
            Tue
          </span>
          <span className="w-[14%] h-10 items-center text-center text-sm font-bold border-t flex justify-center bg-blue text-white">
            Wed
          </span>
          <span className="w-[14%] h-10 items-center text-center text-sm font-bold border-t flex justify-center bg-blue text-white">
            Thu
          </span>
          <span className="w-[14%] h-10 items-center text-center text-sm font-bold border-t flex justify-center bg-blue text-white">
            Fri
          </span>
          <span className="w-[14%] h-10 items-center text-center text-sm font-bold border-r border-t flex justify-center bg-blue text-white">
            Sat
          </span>
        </div>
        <div className="flex gap-0 flex-wrap">
          {days.map((day) => {
            const dayEvents = events?.filter((event) => {
              return (
                format(new Date(event.start_date), "yyyy-MM-dd") ===
                format(day, "yyyy-MM-dd")
              );
            });

            const isLastMonth = day.getMonth() !== selectedMonth.getMonth();
            const isMonOrSun = day.getDay() === 0 || day.getDay() === 1;

            return (
              <div
                key={day.getTime()}
                className={classNames(
                  "w-[14%] min-h-[100px] border p-2 border-gray-300 relative pt-6",
                  isLastMonth && "bg-gray-100"
                )}
              >
                <span
                  className={classNames(
                    "text-xs absolute top-1 left-1",
                    isLastMonth && "text-gray-400"
                  )}
                >
                  <span>{format(day, "do")}</span>
                </span>
                {dayEvents?.map((event) => (
                  <div className="flex gap-1 relative">
                    {event.id === showEventDetails?.id && (
                      <div
                        className={classNames(
                          `absolute top-0 w-44 rounded-lg shadow-lg bg-black/80 text-white z-10 p-4`,
                          isMonOrSun ? "left-full" : "right-full"
                        )}
                      >
                        <button
                          onClick={() => setShowEventDetails(null)}
                          className="absolute top-1 right-1 text-xs"
                        >
                          <XMarkIcon className="w-3" />
                        </button>
                        <img src={event.image_url} className="w-full h-24" />
                        <h3 className="text-lg font-bold my-1">{event.name}</h3>
                        <span className="text-xs text-gray-300 block">
                          {format(
                            new Date(event.start_date),
                            "E, MMM do @ h:mm a"
                          )}
                        </span>
                        <p className="my-2">{event.description}</p>
                        <span>
                          From:{" "}
                          <span className="text-lightPurple">
                            {formatCurrency(event.lowest_price)}
                          </span>
                        </span>
                        <Link to={`/events/${event.slug}`}>
                          <Button className="w-full mt-5">Buy Tickets</Button>
                        </Link>
                      </div>
                    )}
                    <span className="p-1 bg-lightPurple rounded-full w-1 block h-1 mt-1" />
                    <button
                      onClick={() => setShowEventDetails(event)}
                      className="text-left text-xs text-lightPurple font-semibold leading-4 block"
                    >
                      {event.name} @ {event.primary_venue?.name}
                    </button>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
