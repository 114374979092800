import { useAuth } from "providers/AuthContext";
import { Formik, Form, Field } from "formik";
import { RequestProps } from "utils/api";
import { makeApiRequest } from "utils/api";
import { TextInput } from "components/forms";
import { Button } from "components/buttons";
import { useNotification } from "providers/NotificationContext";

type PayloadProps = {
  first_name: string;
  last_name: string;
  email: string;
};

export const UserForm = () => {
  const { user } = useAuth();
  const { showSuccess, showError } = useNotification();

  const updateUser = (userId: number, payload: PayloadProps) => {
    const requestProps: RequestProps = {
      path: `/users/${userId}`,
      method: "PUT",
      params: {
        user: payload,
      },
    };
    makeApiRequest(requestProps).then((res) => {
      if (res.status === 200) {
        showSuccess("Account updated successfully!");
      } else {
        showError("Error updating user, please try again.");
      }
    });
  };
  if (!user) {
    return null;
  }
  return (
    <Formik
      initialValues={{
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_number,
        email: user.email,
      }}
      onSubmit={(data) => updateUser(user.id, data)}
    >
      <Form>
        <div className="flex justify-center gap-3">
          <div className="w-1/2">
            <Field name="first_name">
              {({
                field,
                form,
                meta,
              }: {
                field: any;
                form: any;
                meta: any;
              }) => (
                <TextInput
                  label="First Name"
                  name="first_name"
                  type="text"
                  placeholder="First Name"
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="w-1/2">
            <Field name="last_name">
              {({
                field,
                form,
                meta,
              }: {
                field: any;
                form: any;
                meta: any;
              }) => (
                <TextInput
                  label="Last Name"
                  name="last_name"
                  type="text"
                  placeholder="Last Name"
                  {...field}
                />
              )}
            </Field>
          </div>
        </div>
        <div className="mt-3">
          <Field name="email">
            {({ field, form, meta }: { field: any; form: any; meta: any }) => (
              <TextInput
                label="Email Address"
                name="email"
                type="email"
                placeholder="Email Address"
                {...field}
              />
            )}
          </Field>
        </div>

        <div className="mt-3">
          <Field name="phone_number">
            {({ field, form, meta }: { field: any; form: any; meta: any }) => (
              <TextInput
                label="Phone Number"
                name="phone_number"
                type="text"
                placeholder="Phone Number"
                {...field}
              />
            )}
          </Field>
        </div>

        <Button variant="primary" type="submit" className="mt-3">
          Save
        </Button>
      </Form>
    </Formik>
  );
};
