import { Button } from "components/buttons";
import { Navbar } from "components/navigation/Navbar";
import { FallbackProps } from "react-error-boundary";
import { Link } from "react-router-dom";

export const renderErrorPage = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  return (
    <>
      <div
        role="alert"
        className="w-screen h-screen fixed flex flex-col items-center justify-center px-4"
      >
        <iframe
          src="https://giphy.com/embed/zvBuF2oYRErVS"
          width={window.innerWidth > 640 ? "480" : "360"}
          height="270"
          className="giphy-embed rounded-lg shadow-lg mb-10"
          allowFullScreen
        />
        <span className="text-4xl">Bummmer!</span>{" "}
        <span>We had an error on our side:</span>
        <span className="text-center text-red-400">{error.message}</span>
        <div className="mt-10 flex justify-center items-center gap-5">
          <Button variant="primary" onClick={resetErrorBoundary}>
            Try Again
          </Button>
          <a className="text-blue" href="/">
            Go Back Home
          </a>
        </div>
      </div>
    </>
  );
};
