export const VendorAgreement = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold text-center mb-6">
        HAPPY TICKETING SERVICE AGREEMENT
      </h1>

      <p className="mb-4">
        This Service Agreement ("Agreement") is entered into by and between
        Happy Ticketing, LLC ("HAPPY," "we," or "us") and the entity identified
        during the registration process ("Client," "you," or "your"). By
        clicking "I Agree," the individual accepting this Agreement represents
        and warrants that they are authorized to bind the Client to this
        Agreement, and that the Client agrees to be bound by its terms.
      </p>
      <p className="mb-4">
        HAPPY provides a platform to allow the Client to make online ticket
        sales to its customers ("Customers"). For good and valuable
        consideration, the receipt and sufficiency of which are hereby
        acknowledged, the parties agree as follows:
      </p>

      <h2 className="text-xl font-semibold mb-2">1. Definitions</h2>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>"Agreement"</strong>: This Service Agreement and any
          amendments or attachments hereto.
        </li>
        <li>
          <strong>"Client"</strong>: You, the entity or individual entering into
          this Agreement with HAPPY.
        </li>
        <li>
          <strong>"Customer"</strong>: Any end-user who purchases tickets
          through the HAPPY Platform.
        </li>
        <li>
          <strong>"Event"</strong>: Any event organized by the Client for which
          tickets are sold via the Platform.
        </li>
        <li>
          <strong>"HAPPY"</strong>: Happy Ticketing, LLC.
        </li>
        <li>
          <strong>"Platform"</strong>: The online ticketing system provided by
          HAPPY.
        </li>
        <li>
          <strong>"Services"</strong>: The services provided by HAPPY under this
          Agreement.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mb-2">2. Scope of Agreement</h2>
      <p className="mb-4">
        The Client agrees to use HAPPY as its online ticketing provider via an
        online box office that HAPPY shall provide.
      </p>

      <h2 className="text-xl font-semibold mb-2">3. Fees and Payment Terms</h2>

      <h3 className="text-lg font-medium mb-2">3.1 Online Transactions</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Ticket Face Value</strong>: Less any discounts applied.
        </li>
        <li>
          <strong>Processing Fees</strong>: 2.99% of the total order + $0.30
        </li>
        <li>
          <strong>HAPPY Fee</strong>: $1.50 per
          ticket
        </li>
        <li>
          <strong>Sales Tax</strong>: Calculated based on the event's zip code
          (if applicable)
        </li>
      </ul>

      <h3 className="text-lg font-medium mb-2">
        3.2 Point of Sale (POS) Transactions
      </h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Ticket Face Value</strong>: Less any discounts applied.
        </li>
        <li>
          <strong>HAPPY Fee</strong>: $1.50 per
          ticket
        </li>
        <li>
          <strong>Processing Fees (Ticket orders)</strong>: 2.99% of the total
          order + $0.30
        </li>
        <li>
          <strong>Processing Fees (Concession orders)</strong>: 2.7% of the
          total order + $0.15
        </li>
        <li>
          <strong>Sales Tax</strong>: Calculated based on the event's zip code
          (if applicable)
        </li>
      </ul>

      <h3 className="text-lg font-medium mb-2">3.3 Payment to Client</h3>
      <p className="mb-4">
        The full base ticket price of tickets sold (less any discounts, cash
        fees owed, and refunds, plus any taxes collected) will be paid to you
        within thirty (30) days after the event is concluded.
      </p>

      <h3 className="text-lg font-medium mb-2">
        3.4 Sales Tax Responsibilities
      </h3>
      <p className="mb-4">
        HAPPY will collect sales tax based on the event's zip code if applicable
        and activated by the Client. The remittance of collected sales tax to
        the appropriate authorities is the sole responsibility of the Client.
        HAPPY is not liable for any sales tax remittance of any kind.
      </p>

      <h2 className="text-xl font-semibold mb-2">4. Services Provided</h2>
      <p className="mb-4">
        HAPPY will provide an online ticketing platform along with cloud
        hosting, technical services, and support for you. HAPPY will also
        provide customer support to the best of its ability if your customers
        contact HAPPY directly.
      </p>

      <h2 className="text-xl font-semibold mb-2">5. Client Responsibilities</h2>

      <h3 className="text-lg font-medium mb-2">5.1 Compliance with Laws</h3>
      <p className="mb-4">
        The Client agrees to comply with all applicable laws and regulations in
        connection with its use of the HAPPY Platform and the organization of
        Events. The Client is solely responsible for obtaining all necessary
        permits, licenses, and approvals required to host and operate Events.
      </p>

      <h3 className="text-lg font-medium mb-2">5.2 Accurate Information</h3>
      <p className="mb-4">
        The Client shall provide accurate and complete information in connection
        with the sale of tickets and shall not engage in any fraudulent or
        illegal activities or any activities that may harm the reputation or
        operation of HAPPY.
      </p>

      <h3 className="text-lg font-medium mb-2">5.3 Ticket Validity</h3>
      <p className="mb-4">
        You are responsible for honoring all valid tickets sold through the
        HAPPY Platform that have not been altered and were paid for in full
        without dispute.
      </p>

      <h2 className="text-xl font-semibold mb-2">
        6. Refunds and Event Policies
      </h2>
      <h3 className="text-lg font-medium mb-2">6.1 Client's Policies</h3>
      <p className="mb-4">
        You are responsible for any refunds and for implementing a refund policy
        and any other event-related policies.
      </p>

      <h3 className="text-lg font-medium mb-2">6.2 Visibility of Policies</h3>
      <p className="mb-4">
        These policies must be visibly displayed on your event listing page
        within the HAPPY Platform and on your own website, and conveyed to any
        customers who purchase tickets through the HAPPY Platform.
      </p>

      <h3 className="text-lg font-medium mb-2">6.3 Refund Process</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Refund Authorization</strong>: Refunds can be authorized
          before the event has started, up to the ticket base price (processing
          and HAPPY fees excluded).
        </li>
        <li>
          <strong>Refund Deductions</strong>: Any refunds will be deducted from
          your account balance.
        </li>
        <li>
          <strong>Time Limitations</strong>: Refunds will not be allowed once
          the event has started.
        </li>
      </ul>

      {/* Continue formatting similarly for the rest of the sections */}
    </div>
  );
};
