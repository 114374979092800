import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { TextArea, TextInput } from "./forms";
import { Button } from "./buttons";
import { useRef, useState } from "react";
import { useNotification } from "providers/NotificationContext";
import { makeApiRequest } from "utils/api";
export const HappySupportGuy = ({}: {}) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { showError, showSuccess } = useNotification();
  const popoverRef = useRef<HTMLButtonElement | null>(null);
  const createContact = async () => {
    if (!email || !message) {
      showError("Please fill in all fields");
      return;
    }

    const payload = { email, message, contact_type: "support_guy" };

    makeApiRequest({
      path: "/contacts",
      method: "POST",
      params: {
        contact: payload,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess("Message sent successfully. Thank you for reaching out!");
          setEmail("");
          setMessage("");
          if (popoverRef.current) popoverRef.current.click();
          return;
        }
      })
      .catch((err) => {
        showError("There was an error sending your message. Please try again.");
      })
  };

  return (
    <div id="support-guy" className="fixed bottom-20 right-2 sm:right-12 z-20">
      <Popover className="relative">
        <PopoverButton
          ref={popoverRef}
          className={
            "p-3 rounded-full bg-pink flex items-center gap-2 text-white"
          }
        >
          <ChatBubbleLeftIcon className="w-6 h-6" />
        </PopoverButton>
        <PopoverPanel
          anchor="top"
          className="flex flex-col p-4 bg-white rounded-lg w-80 shadow-lg"
        >
          <div>
            <h3 className="text-lg font-semibold mb-4">
              Need help? Let us know your issue.
            </h3>
            <TextInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              placeholder="Email Address"
              type="text"
            />
            <TextArea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="resize-none"
              name="message"
              placeholder="What's the issue?"
            />
            <Button
              onClick={createContact}
              className="w-full"
              variant="default"
            >
              Send
            </Button>
          </div>
        </PopoverPanel>
      </Popover>
    </div>
  );
};
