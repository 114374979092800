import { Navbar } from "components/navigation/Navbar";
import { useAuth } from "providers/AuthContext";
import { useState } from "react";
import { Button } from "components/buttons";
import { LoginForm, RegisterForm } from "components/forms/Auth";
import { GLogin } from "components/forms/GLogin";
import { useNotification } from "providers/NotificationContext";
import { OtpForm } from "./OtpForm";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Login } from "./Login";

export const Auth = () => {
  return (
    <>
      <Navbar />
      <Login />
    </>
  );
};
