import { Formik, Field, Form } from "formik";
import { TextInput } from ".";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components/buttons";
import { Spinner } from "components/loaders/Spinner";
import { useState } from "react";
import { show } from "@intercom/messenger-js-sdk";

export const LoginForm = ({
  handleSubmit,
  loading,
  handleLinkClick,
  setShowOtpForm,
}: {
  setShowOtpForm?: (value: boolean) => void;
  handleLinkClick?: () => void;
  handleSubmit: (values: { email: string; password: string }) => void;
  loading?: boolean;
}) => {
  const navigate = useNavigate();

  const navigateToCreateAccountPage = () => {
    navigate("/register");
  };
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values, actions) => {
        handleSubmit(values);
      }}
    >
      <Form>
        <div className="rounded-md -space-y-px flex flex-col gap-4">
          <Field name="email">
            {({ field, form, meta }: { field: any; form: any; meta: any }) => (
              <TextInput
                label="Email"
                placeholder="Email address"
                {...field}
                type="email"
              />
            )}
          </Field>

          <Field name="password">
            {({ field, form, meta }: { field: any; form: any; meta: any }) => (
              <TextInput
                label="Password"
                placeholder="Password"
                {...field}
                type="password"
              />
            )}
          </Field>
        </div>
        <div className="text-sm flex justify-between mt-4">
          <Button
            onClick={(e) => {
              e.preventDefault();
              if (handleLinkClick) return handleLinkClick();
              return navigateToCreateAccountPage();
            }}
            variant="link"
          >
            Create an account
          </Button>
          <Link to="/forgot-password">
            <Button variant="link">Forgot your password?</Button>
          </Link>
        </div>
        <div className="flex items-center justify-between py-6">
          <Button variant="primary" className="mr-2" type="submit">
            {loading ? <Spinner /> : "Log In"}
          </Button>
          
          {setShowOtpForm && (
            <Button onClick={() => setShowOtpForm(true)}>
              Use SMS
            </Button>
          )}
        </div>
      </Form>
    </Formik>
  );
};

export const RegisterForm = ({
  handleSubmit,
  loading,
  handleLinkClick,
}: {
  handleLinkClick?: () => void;
  handleSubmit: (values: {
    email: string;
    phone_number: string;
    password: string;
    first_name: string;
    last_name: string;
  }) => void;
  loading?: boolean;
}) => {
  const [tos, setTos] = useState<boolean>(false);
  const navigate = useNavigate();

  const navigateToLoginPage = () => {
    navigate("/login");
  };
  return (
    <Formik
      initialValues={{
        full_name: "",
        phone_number: "",
        email: "",
        password: "",
      }}
      onSubmit={(values) => {
        const firstName = values.full_name.split(" ")[0];
        const lastName = values.full_name.split(" ")[1] || "";
        const email = values.email;
        const phone = values.phone_number;
        const password = values.password;
        const payload = {
          email,
          phone_number: phone,
          first_name: firstName,
          last_name: lastName,
          password,
        };
        handleSubmit(payload);
      }}
    >
      <Form>
        <div className="flex flex-col gap-4 mt-5">
          <Field name="full_name">
            {({ field, form, meta }: { field: any; form: any; meta: any }) => (
              <TextInput
                label="Full Name*"
                placeholder="Jeff Lebowski"
                {...field}
                type="text"
              />
            )}
          </Field>
          <div className="mt-1">
            <Field name="email">
              {({
                field,
                form,
                meta,
              }: {
                field: any;
                form: any;
                meta: any;
              }) => (
                <TextInput
                  label="Email*"
                  placeholder="Email address"
                  {...field}
                  type="email"
                />
              )}
            </Field>
          </div>
          <div className="mt-1">
            <Field name="password">
              {({
                field,
                form,
                meta,
              }: {
                field: any;
                form: any;
                meta: any;
              }) => (
                <TextInput
                  label="Password*"
                  placeholder="Password"
                  {...field}
                  type="password"
                />
              )}
            </Field>
          </div>
          <div className="mt-1">
            <Field name="phone_number">
              {({
                field,
                form,
                meta,
              }: {
                field: any;
                form: any;
                meta: any;
              }) => (
                <TextInput
                  label="Phone #"
                  placeholder="Phone Number"
                  {...field}
                  type="text"
                />
              )}
            </Field>
            <span className="text-[10px] text-gray-500 leading-1 block mt-1">
              By providing your phone number, you agree to receive
              account-related notifications via SMS from Happy Ticketing.
              Message and data rates may apply.
            </span>
          </div>
        </div>
        <div className="text-sm flex justify-between mt-4">
          <Button
            onClick={(e) => {
              e.preventDefault();
              if (handleLinkClick) return handleLinkClick();

              return navigateToLoginPage();
            }}
            variant="link"
          >
            Already a member?
          </Button>
        </div>
        <div className="py-6">
          <div className="flex items-center w-full mb-5">
            <input
              type="checkbox"
              name="tos"
              id="tos"
              checked={tos}
              onChange={() => setTos(!tos)}
              className="mr-2"
            />
            <label htmlFor="tos" className="text-xs">
              I agree to Happy Ticketing's{" "}
              <Link
                to="/terms-of-service"
                target="_blank"
                className="text-purple"
              >
                Terms of Service
              </Link>
            </label>
          </div>

          <Button
            disabled={!tos}
            variant="primary"
            className="w-full"
            type="submit"
          >
            {loading ? <Spinner /> : "Create Account"}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
