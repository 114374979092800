import {
  CurrencyDollarIcon,
  FlagIcon,
  MapPinIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/solid";
import { Container } from "components/layout";
import { Link } from "react-router-dom";
import { Event } from "types/Event";

export const EventHeader = ({ event }: { event: Event }) => {
  const primaryVenue = event?.primary_venue;

  return (
    <Container className="py-5 xl:py-12 lg:py-10">
      <div className="w-full flex flex-col sm:flex-row items-center gap-4 md:gap-10">
        <div className="w-44 h-44 sm:w-60 sm:h-60 lg:w-80 lg:h-80 rounded-full bg-gradient-to-tr from-pink to-purple p-1 sm:p-2">
          <img
            src={event.image_url}
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="w-full items-center sm:w-2/3 flex flex-col gap-2 sm:gap-10 justify-between h-full">
          <div className="w-full">
            <span className="block text-2xl text-center sm:text-left font-extrabold md:text-3xl lg:text-5xl">
              {event.name}
            </span>
            {event.description && (
              <p className="mt-2 sm:text-lg text-center sm:text-left">
                {event.description}
              </p>
            )}
          </div>
          {}
          <div className="w-full flex flex-col gap-1">
            <span className="mb-2 text-lg font-light block sm:text-2xl text-center sm:text-left">
              @ {primaryVenue?.name}
            </span>
            {event.organization && (
              <span className="justify-center sm:justify-start sm:text-left text-xs sm:text-base flex items-center gap-2">
                <FlagIcon className="h-4 w-4 inline-block" />
                <span>
                  Presented By:{" "}
                  <Link
                    className="text-blue font-bold hover:brightness-110"
                    to={`/orgs/${event.organization.id}`}
                  >
                    {event.organization.name}
                  </Link>
                </span>
              </span>
            )}
            <span className="justify-center sm:justify-start text-xs sm:text-base flex items-center gap-2">
              <MapPinIcon className="h-4 w-4 inline-block" />
              <span>
                {event.primary_venue.address_1}, {event.primary_venue.city},{" "}
                {event.primary_venue.state}
              </span>
            </span>
            <span className="justify-center sm:justify-start text-xs sm:text-base flex items-center gap-2">
              <CalendarDaysIcon className="h-4 w-4 inline-block" />
              <span>{event.start}</span>
            </span>

            {event.refund_policy && (
              <span className="justify-center sm:justify-start text-xs sm:text-base flex items-center gap-2">
                <span>
                  <CurrencyDollarIcon className="h-4 w-4 mr-1 inline-block" />{" "}
                  Refund Policy: {event.refund_policy}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
